<template>
  <v-container
    fluid
    style="padding: 0;"
  >
    <v-card>
      <v-img
        :src="require('../assets/main_logo.jpeg')"
        class="align-center"
        cover
        max-height="400px"
        style="color: #000000"
      >
        <v-card-title>
          <v-avatar
            size="96px"
          >
            <img
              :src="require('../assets/logo.png')"
              alt="Avatar"
              height="68"
            >
          </v-avatar>
          <span class="text-h4 font-weight-bold ml-2"><b>СтройКранПроект</b></span>
        </v-card-title>
        <v-card-subtitle class="text-h6 font-weight-medium mb-3" style="margin-left: 5%">
          <div class="mb-1">Ваш лучший партнер</div>
          <div>в поиске строительной техники</div>
        </v-card-subtitle>
      </v-img>
    </v-card>

    <v-row
      text-center class="align-center justify-center"
    >
      <v-col class="ma-5">
        <v-sheet
          class="mx-auto"
          style="max-width: 1000px;"
        >
          <div id="link1" style="margin-bottom: 30px;">
            <v-row>
              <v-col
                sm="12"
                md="6"
                xs="12"
              >
                <v-img
                  style="margin: 10px; border-radius: 20px;"
                  height="100%"
                  :src="require('../assets/about.jpg')"
                ></v-img>
              </v-col>
              <v-col
                sm="12"
                md="6"
                xs="12"
              >
                <v-row class="text-center mt-3">
                  <v-col class="mb-6">
                    <h4 class="display-1 mb-3">
                      О нас
                    </h4>
                  </v-col>
                </v-row>
                <div
                  class="text-info"
                  style="margin: 10px;"
                  v-html="slides[0] + '<br><br>' + slides[3]"
                >
                </div>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-parallax
      dark
      height="300"
      :src="require('../assets/contact_with_as.png')"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <h1 class="text-h4 font-weight-thin mb-4">
            Свяжитесь с нами сейчас
          </h1>
          <h4 class="subheading mb-3">
            Чтобы строить уже завтра!
          </h4>
          <v-btn
            rounded
            text
            color="#ffffff"
            style="background: #26c6da; margin-left: 5%"
            @click="scrollTo('link4')"
          >
            Контакты
          </v-btn>
        </v-col>
      </v-row>
    </v-parallax>
    <v-row
      text-center class="align-center justify-center"
    >
      <v-col class="ma-5">
        <v-sheet
          class="mx-auto"
          style="max-width: 800px;"
        >
          <div id="link2" style="margin-bottom: 10px;">
            <v-row class="text-center mt-3">
              <v-col class="mb-6">
                <h4 class="display-1 mb-3">
                  Наша техника:
                </h4>
              </v-col>
            </v-row>
            <v-dialog
              v-model="dialogOpened"
              max-width="350"
            >
              <v-card
                class="mx-auto"

              >
                <v-img
                  height="250px"
                  dark
                  :src="selectedEquipment.image"
                >
                  <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                  >

                    <v-spacer></v-spacer>

                    <v-btn
                      color="white"
                      icon
                      @click="dialogOpened=false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-app-bar>
                  <v-spacer></v-spacer>
                  <v-card-title class="white--text pt-16">
                    <div class="text-h5 pt-16">
                      {{ selectedEquipment.name }}
                    </div>
                  </v-card-title>
                </v-img>
                <v-card-text class="pt-2 pb-2" v-html="selectedEquipment.about">
                </v-card-text>
              </v-card>
            </v-dialog>
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-for="equipment in equipments"
                  :key="equipment.name"
                  lg="6"
                  sm="6"
                  md="6"
                  xs="12"
                >
                  <v-card>
                    <v-img
                      :src="equipment.image"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                      @click="showEquipment(equipment)"
                      style="cursor: pointer;"
                    >
                      <v-card-title v-text="equipment.name"></v-card-title>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row class="text-center mt-2">
      <v-col class="mb-6">
        <h4 class="display-1 mb-3">
          Преимущества работы с нами:
        </h4>
      </v-col>
    </v-row>

    <v-item-group>
      <v-container>
        <v-row>
          <v-col
            v-for="(card, n) in cards"
            :key="n"
            cols="12"
            md="4"
          >
            <v-item v-slot="{}">
              <v-card
                :color="card.color"
                class="mx-auto"
                dark
                height="230"
              >
                <v-scroll-y-transition>
                  <div>
                    <v-card-title style="color: white">
                      <v-icon
                        large
                        left
                      >
                        {{ card.icon }}
                      </v-icon>
                      <span class="font-weight-light text-sm-h6">{{ card.topic }}</span>
                    </v-card-title>

                    <v-card-text
                        class="font-weight-light"
                        style="color: white; font-size: 1rem;"
                        v-html="card.description"
                    >
                    </v-card-text>
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>

    <v-row class="text-center mt-2">
      <v-col class="mb-6">
        <h4 class="display-1 mb-3">
          Осуществляем доставку строительной техники по всей России
        </h4>
      </v-col>
    </v-row>
    <v-row class="text-center" align="center" justify="center">
        <v-img
            :aspect-ratio="16/9"
            max-width="800"
            :src="require('../assets/russia-map.png')">
        </v-img>
    </v-row>

    <div id="link4" style="min-height: 150px; margin: 10px;">
      <ContactsDialog
        :settings="contacts"
      />
    </div>

    <v-footer
      dark
      padless
      class="mt-12"
    >
      <v-card
        flat
        tile
        class="indigo lighten-1 white--text text-center"
        width="100%"
      >
        <v-card-text>
          <v-row class="ml-10 mr-10">
            <v-col
              v-for="icon in icons"
              :key="icon.iconpath"
              justify="center"
            >
              <v-tooltip
                bottom
                class="mx-4 white--text"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a :href="icon.href" class="shrink mr-2">
                    <v-img
                      :src="icon.iconpath"
                      transition="scale-transition"
                      max-width="30"
                      class="mx-auto"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </a>
                </template>
                <span>{{ icon.description }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          ООО СтройКранПроект - Ваш лучший партнер в поиске строительного крана
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>ООО СтройКранПроект</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import ContactsDialog from "@/components/Contacts/ContactsDialog";
export default {
  name: 'LeadPage',
  components: {
    ContactsDialog,
  },
  data: () => ({
    dialogOpened: false,

    link: 'https://lk.profi-time.com/',
    register_link: 'https://lk.profi-time.com/register',
    icons: [
      {
        iconpath: require('../assets/gmail.svg'),
        href: 'mailto:viktor.ilyn88@mail.ru?subject=Купить кран&body=Нужен кран',
        description: 'Написать письмо'
      },
      {
        iconpath: require('../assets/telegram.svg'),
        href: 'https://t.me/@+79176708809',
        description: 'Телеграм канал'
      },
      {
        iconpath: require('../assets/vk.svg'),
        href: 'https://vk.com/id8811050',
        description: 'Группа VK'
      },
    ],
    cards: [
      {
        color: '#26c6da',
        icon: 'mdi-crane',
        topic: 'Ассортимент',
        description: 'Предлагаем широкий ассортимент башенных кранов, которые отличаются надежностью, эффективностью и современными характеристиками',
      },
      {
        color: '#952175',
        icon: 'mdi-account-multiple-plus-outline',
        topic: 'Команда',
        description: 'Наша команда готова предложить Вам не только продукцию, но и экспертные консультации по выбору оборудования, его эксплуатации и обслуживанию.',
      },
      {
        color: '#385F73',
        icon: 'mdi-poll',
        topic: 'Анализ',
        description: 'Каждый наш кран проходит тщательную проверку и соответствует всем международным стандартам качества.',
      },
    ],
    information: [
      {
        color: 'cyan',
        topic: 'Электронный журнал',
        description: 'Расписание записей Вашей компании, ' +
            'в котором могут работать все Ваши сотрудники совместно.',
        link: process.env.BASE_URL+'images/schedule.png'
      },
      {
        color: 'red',
        topic: 'Учет смен сотрудников',
        description: 'Позволяет распределить рабочее время между сотрудниками. Каждый сотрудник может сам сформировать свой график работы,' +
            ' а Вы контролировать учет рабочего времени',
        link: process.env.BASE_URL+'images/shifts.png'
      },
      {
        color: 'orange',
        topic: 'Онлайн-запись',
        description: 'Клиенты самостоятельно могут записаться к Вам онлайн! Просто разместите ссылку онлайн-записи в Ваших социальных сетях',
        link: process.env.BASE_URL+'images/online.png'
      },
      {
        color: 'green',
        topic: 'Работа в команде',
        description: 'Каждый участник команды может видеть свое расписание, показатели эффективности, участвовать в раскрутке самого себя онлайн',
        link: process.env.BASE_URL+'images/comand.png'
      },
      {
        color: 'pink',
        topic: 'Анализ клиентов',
        description: 'Анализируйте своих клиентов! Изучайте как часто они приходят к Вам и на какие услуги записываются',
        link: process.env.BASE_URL+'images/analytics.png'
      },
      {
        color: 'green',
        topic: 'Экспорт данных',
        description: 'Не хватает инструментов для анализа - экспортируйте свои данные в формате EXCEL',
        link: process.env.BASE_URL+'images/excel.png'
      },
      {
        color: 'amber',
        topic: 'Смотрите вперед',
        description: 'Развивайтесь вместе с нами, чтобы достичь наилучшего успеха!',
        link: process.env.BASE_URL+'images/clients.png'
      },
      {
        color: 'orange',
        topic: 'И все это абсолютно бесплатно!',
        year: '2000',
      },
    ],
    slides: [
        'СтройКранПроект - надежный поставщик строительной техники!<br><br>Компания основана группой специалистов с богатым опытом в строительной сфере. Мы сосредоточены на продаже башенных кранов различных типов и конструкций. Мы активно внедряем инновационные технологии и обеспечиваем высокий уровень сервиса!',
        '"СтройКранПроект" предлагает широкий ассортимент башенных кранов, которые отличаются надежностью, эффективностью и современными характеристиками. Наша команда готова предложить клиентам не только продукцию, но и экспертные консультации по выбору оборудования, его эксплуатации и обслуживанию.',
        'Мы понимаем, как важна надежность техники на строительной площадке, поэтому каждый наш кран проходит тщательную проверку и соответствует всем международным стандартам качества. Благодаря современным материалам и технологиям, мы уверены, что наши краны помогут вам успешно реализовать любые проекты – от малых до масштабных.',
        'Становитесь частью нашей успешной истории и доверьте свои строительные задачи компании "СтройКранПроект"!'
    ],
    contacts: {
      "addresses": [
        {
            "data": {
                "qc": null,
                "area": null,
                "city": "Чебоксары",
                "flat": null,
                "room": null,
                "block": null,
                "floor": null,
                "house": "1",
                "metro": null,
                "okato": "97401000000",
                "oktmo": "97701000",
                "stead": null,
                "qc_geo": "0",
                "region": "Чувашская республика",
                "source": null,
                "street": "Школьный",
                "country": "Россия",
                "fias_id": "9e762fef-0a73-4a94-909d-100345bce0f2",
                "geo_lat": "56.12257",
                "geo_lon": "47.2609",
                "entrance": null,
                "kladr_id": "2100000100004650001",
                "qc_house": null,
                "timezone": null,
                "area_type": null,
                "city_area": null,
                "city_type": "г",
                "divisions": null,
                "fias_code": null,
                "flat_area": null,
                "flat_type": null,
                "room_type": null,
                "block_type": null,
                "fias_level": "8",
                "flat_price": null,
                "geoname_id": "569696",
                "house_type": "д",
                "postal_box": null,
                "settlement": null,
                "stead_type": null,
                "tax_office": "2100",
                "beltway_hit": null,
                "flat_cadnum": null,
                "postal_code": "428003",
                "qc_complete": null,
                "region_type": "Чувашия",
                "room_cadnum": null,
                "street_type": "проезд",
                "area_fias_id": null,
                "city_fias_id": "dd8caeab-c685-4f2a-bf5f-550aca1bbc48",
                "flat_fias_id": null,
                "house_cadnum": null,
                "room_fias_id": null,
                "stead_cadnum": null,
                "area_kladr_id": null,
                "city_district": null,
                "city_kladr_id": "2100000100000",
                "house_fias_id": "9e762fef-0a73-4a94-909d-100345bce0f2",
                "stead_fias_id": null,
                "area_type_full": null,
                "area_with_type": null,
                "capital_marker": "2",
                "city_type_full": "город",
                "city_with_type": "г Чебоксары",
                "flat_type_full": null,
                "history_values": null,
                "house_kladr_id": "2100000100004650001",
                "region_fias_id": "878fc621-3708-46c7-a97f-5a13a4176b3e",
                "room_type_full": null,
                "street_fias_id": "169055ad-eade-490d-ab72-36bc434e4a80",
                "unparsed_parts": null,
                "block_type_full": null,
                "house_type_full": "дом",
                "region_iso_code": "RU-CU",
                "region_kladr_id": "2100000000000",
                "settlement_type": null,
                "stead_type_full": null,
                "street_kladr_id": "21000001000046500",
                "beltway_distance": null,
                "country_iso_code": "RU",
                "federal_district": "Приволжский",
                "house_flat_count": null,
                "region_type_full": "Чувашия",
                "region_with_type": "Чувашская республика - Чувашия",
                "street_type_full": "проезд",
                "street_with_type": "Школьный проезд",
                "tax_office_legal": "2100",
                "city_district_type": null,
                "settlement_fias_id": null,
                "square_meter_price": null,
                "settlement_kladr_id": null,
                "fias_actuality_state": "0",
                "settlement_type_full": null,
                "settlement_with_type": null,
                "city_district_fias_id": null,
                "city_district_kladr_id": null,
                "city_district_type_full": null,
                "city_district_with_type": null
            },
            "value": "г Чебоксары, Школьный проезд, д 1",
            "unrestricted_value": "428003, Чувашская республика - Чувашия, г Чебоксары, Школьный проезд, д 1"
        }
      ],
      "phones": [
          {
              "phone": "79176708809",
              "description": "Отдел продаж"
          }
      ],
      "emails": [],
      "links": [
          {
              "link": "https://vk.com/id8811050",
              "description": "ВК"
          }
      ],
      "company_card":
        [
          ["ИНН", "2100010422"],
          ["КПП", "210001001"],
          ["ОГРН", "1232100006973"],
          ["Юридический адрес", "428027, Чувашская Республика г. Чебоксары, пр-т 9-й Пятилетки, 5А, офис 4"],
          ["Банковский счет", "№ 40702810775000013363 " +
                              "в Чувашском отделении Nº8613 ПАО " +
                              "Сбербанк России г. Чебоксары " +
                              "ИНН Банка 7707083893, КТП 213002001, 0ГРН " +
                              "1027700132195, ОКПО 09286400, БИК 049706609"],
          ["Директор", "Ильин Виктор Сергеевич (действует на основании Устава общества)"]
        ],
    },

    selectedEquipment: {},
    equipments: [
      {
        name: 'TDK-10.180 б/у',
        about:  'Грузоподъемность 10 TH<br>' +
                'Длина стрелы до 60 м<br>' +
                'Высота подъема в свободном стоянии 53 м<br>' +
                'Высота подьема до 160 м<br>',
        image: require('../assets/equipments/kran_4.png'),
      },
      {
        name: 'TDK-10.180 новый',
        about:  'Грузоподъемность 10 TH<br>' +
                'Длина стрелы до 60 м<br>' +
                'Высота подъема в свободном стоянии 53 м<br>' +
                'Высота подьема до 160 м<br>',
        image: require('../assets/equipments/kran_5.png'),
      },
    ],
  }),
  methods: {
    scrollTo(element_id) {
      document.getElementById(element_id).scrollIntoView();
    },
    showEquipment(equipment){
      this.selectedEquipment = equipment
      this.dialogOpened = true
    },
  },
}
</script>
