<template>
  <div>
    <div
      v-if="settings.emails.length || settings.addresses.length || settings.phones.length || settings.links.length"
    >
      <v-row class="text-center mt-2">
        <v-col class="mb-6">
          <h4 class="display-1 mb-3">
            Контакты:
          </h4>
        </v-col>
      </v-row>
    </div>
    <v-list two-line>
      <v-list-item
        v-for="(data, i) in settings.phones"
        :key="100+i"
      >
        <v-list-item-icon
          v-if="i === 0"
        >
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon>
        <v-list-item-action
          v-else
        ></v-list-item-action>

        <v-list-item-content
            style="cursor: pointer;"
            @click="callPhone(data.phone)"
        >
          <v-list-item-title>{{ make_phone_pretty(data.phone) }}</v-list-item-title>
          <v-list-item-subtitle v-if="data.description" class="text-wrap" v-text="data.description"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon @click="callPhone(data.phone)" color="green">mdi-phone-dial</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="settings.emails.length" inset></v-divider>

      <v-list-item
        v-for="(data, i) in settings.emails"
        :key="200+i"
      >
        <v-list-item-icon
          v-if="i === 0"
        >
          <v-icon color="indigo">
            mdi-email
          </v-icon>
        </v-list-item-icon>
        <v-list-item-action
          v-else
        ></v-list-item-action>

        <v-list-item-content
            style="cursor: pointer;"
            @click="sendEmail(data.email)"
        >
          <v-list-item-title>{{ data.email }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon @click="sendEmail(data.email)"  color="red">mdi-email-fast-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="settings.links.length" inset></v-divider>

      <v-list-item
        v-for="(data, i) in settings.links"
        :key="300+i"
      >
        <v-list-item-icon
          v-if="i === 0"
        >
          <v-icon color="indigo">
            mdi-link
          </v-icon>
        </v-list-item-icon>
        <v-list-item-action
          v-else
        ></v-list-item-action>

        <v-list-item-content
            style="cursor: pointer;"
            @click="openLink(data.link)"
        >
          <v-list-item-title class="text-wrap" v-text="data.link"></v-list-item-title>
          <v-list-item-subtitle v-if="data.description" class="text-wrap" v-text="data.description"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon @click="openLink(data.link)" color="blue">mdi-web</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="settings.addresses.length" inset></v-divider>

      <v-list-item
        v-for="(data, i) in settings.addresses"
        :key="400+i"
      >
        <v-list-item-icon
          v-if="i === 0"
        >
          <v-icon color="indigo">
            mdi-map-marker-radius
          </v-icon>
        </v-list-item-icon>
        <v-list-item-action
          v-else
        ></v-list-item-action>

        <v-list-item-content
            style="cursor: pointer;"
            @click="findPath(data.data)"
        >
          <v-list-item-title class="text-wrap" v-text="data.value"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon
            @click="findPath(data.data)"
            color="orange"
            elevation="10"
          >mdi-map</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider v-if="settings.company_card.length" inset></v-divider>

      <v-list-item
        v-for="(data, i) in settings.company_card"
        :key="400+i"
      >
        <v-list-item-icon
          v-if="i === 0"
        >
          <v-icon color="indigo">
            mdi-card-account-details-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-action
          v-else
        ></v-list-item-action>

        <v-list-item-content>
          <v-list-item-title class="text-wrap" v-html="data[0]"></v-list-item-title>
          <v-list-item-subtitle class="text-wrap" v-html="data[1]"></v-list-item-subtitle>
        </v-list-item-content>

      </v-list-item>

    </v-list>
  </div>
</template>
<script>
  // import TimePicker from "./TimePicker";
  export default {
    // components: {
    //   TimePicker,
    // },
    props: {
      settings: {default: () => {}, type: Object},
    },
    computed: {
      today () {
        return (new Date()).getDay() - 1
      },
      work_week_days () {
        const data = {}
        for (let i = 0; i < 7; i++) {
          if (i in this.settings.work_days)
            data[i] = this.settings.work_days[i]
          else
            data[i] = {
              start_time: this.settings.start_time,
              end_time: this.settings.end_time
            }
        }
        return data
      },
    },
    data () {
      return {
        working_days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье',
        ],
      }
    },
    methods: {
      make_phone_pretty(value){
        if (!value) return null
        let i = 0
        const pretty_phone = '+#(###)###-##-##'.replace(/#/g, () => value[i++] || '')
        return pretty_phone
      },
      callPhone(phone){
        window.open('tel:+' + phone, "_self")
      },
      sendEmail(email) {
        window.open('mailto:' + email, "_self")
      },
      openLink(link) {
        window.open(link)
      },
      findPath (data) {
        const link = `https://www.google.com/maps/@${data.geo_lat},${data.geo_lon},19z?entry=ttu`
        window.open(link)
      }
    },
  }
</script>